<template>
  <div id="app">
    <v-snackbar v-model="snackbartest.visible" auto-height :color="snackbartest.color"
      :multi-line="snackbartest.mode === 'multi-line'" :timeout="snackbartest.timeout"
      :top="snackbartest.position === 'top'">
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>{{ snackbartest.icon }}</v-icon>
        <v-layout column>
          <div>
            <strong>{{ snackbartest.title }}</strong>
          </div>
          <div>{{ snackbartest.text }}</div>
        </v-layout>
      </v-layout>
      <v-btn v-if="snackbartest.timeout === 0" icon @click="snackbartest.visible = false">
        <v-icon>clear</v-icon>
      </v-btn>
    </v-snackbar>
    <v-data-table :headers="headers" :items="list" sort-by="code" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>LISTE DES EVENEMENTS</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>

          <v-dialog v-model="dialog" max-width="650px">
            <v-snackbar v-model="snackbar" :multi-line="multiLine" color="primary">
              {{ text }}

              <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                  Fermer
                </v-btn>
              </template>
            </v-snackbar>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="nouveau()">
                Nouveau
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-form ref="form" v-model="valid" lazy-validation>

                    <v-text-field v-model="titre" label="TITRE *" dense outlined>
                    </v-text-field>

                    <h3>DESCRIPTION *</h3>

                    <ckeditor v-model="description"></ckeditor>

                    <h3>DETAILS *</h3>
                    <!-- <v-textarea v-model="description" label="DESCRIPTION *" dense outlined>
                    </v-textarea> -->

                    <ckeditor v-model="details"></ckeditor>

                    <h3></h3>

                    <v-text-field v-model="lien_reunion" label="LIEN REUNION " dense outlined>
                    </v-text-field>

                    <v-text-field v-model="event_periode" label="PERIODE " dense outlined>
                    </v-text-field>

                    <v-text-field v-model="pays" label="PAYS " dense outlined>
                    </v-text-field>

                    <v-text-field v-model="date_evenement" label="DATE LIMITE *" dense outlined>
                    </v-text-field>

                    <v-file-input v-model="lien_image" label="BANIERE *" dense outlined>
                    </v-file-input>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="blue darken-1" text @click="dialog = false">
                  Fermer
                </v-btn>

                <v-btn color="blue darken-1" text @click="save">
                  Enregistrer
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Voullez vous vraiment supprimer?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDelete = false">Annuller</v-btn>
                <v-btn color="blue darken-1" text @click="supdata()">Confirmer</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:[`item.description`]="{ item }">
        <span v-html=item.description></span>
      </template>

      <template v-slot:[`item.details`]="{ item }">
        <span v-html=item.details></span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="Actulisation">
          Actualiser la liste
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import api from "./../../../serviceApi/apiService";

export default {
  data: () => ({
    multiLine: true,
    snackbartest: false,
    textmessage: `message`,

    snackbar: false,
    snackbars: false,
    text: `message`,
    textsnackbar: "messages",
    dialog: false,
    dialogDelete: false,
    textetat: false,
    headers: [
      { text: "ID", value: "id", divider: true, width: "30px" },
      { text: "TITRE", value: "titre", divider: true, width: "150px" },
      {
        text: "DESCRIPTION",
        value: "description",
        divider: true,
        width: "450px",
      },
      { text: "DETAILS", value: "details", divider: true, width: "450px" },
      { text: "PAYS", value: "pays", divider: true, width: "150px" },
      {
        text: "LIEN IMAGE",
        value: "lien_image",
        divider: true,
        width: "200px",
      },
      {
        text: "LIEN REUNION",
        value: "lien_reunion",
        divider: true,
        width: "200px",
      },
      {
        text: "PERIODE",
        value: "event_periode",
        divider: true,
        width: "200px",
      },
      {
        text: "DATE LIMITE",
        value: "date_evenement",
        divider: true,
        width: "150px",
      },
      { text: "ACTIONS", value: "actions", divider: true, width: "100px" },
    ],
    list: [],
    id: "0",
    titre: "",
    description: "",
    details: "",
    pays: "",
    lien_image: "",
    lien_reunion: "",
    event_periode: "",
    date_evenement: "",
    textetat: false,
    formTitle: "Formulaire d'enregistrement",
  }),
  methods: {
    //=====Afficher tout=======
    readAll: async function () {
      const data = await api.readAll("events/all");
      this.list = data.list;
    },

    createUpdatedata: async function () {
      let fd = new FormData();
      fd.append("titre", this.titre);
      fd.append("description", this.description);
      fd.append("details", this.details);
      fd.append("pays", this.pays);
      fd.append("lien_image", this.lien_image.name);
      fd.append("lien_reunion", this.lien_reunion);
      fd.append("event_periode", this.event_periode);
      fd.append("date_evenement", this.date_evenement);
      fd.append("id", this.id);

      if (this.textetat) { // update event
        //console.log("update event");
        if (this.lien_image != "") {
            //console.log("update event with image "+this.lien_image);
          let fp = new FormData();
          fp.append("id", this.id);
          fp.append("name", this.lien_image.name);
          fp.append("image", this.lien_image);


          const up = await api.upload_Only("events/upload", fp);

          if (up.status == 200) {
            const res = await api.createUpdatedata(
              "events/update/" + this.id,
              fd
            );
            if (res.status == 200) {
              this.textmessage = res.message;
              this.snackbartest = {
                color: "green",
                icon: "mdi-check-all",
                mode: "multi-line",
                position: "top",
                timeout: 2500,
                title: "Information",
                text: this.textmessage,
                visible: true,
              };
              this.dialog = false;
            } else {
              this.textmessage = res.message;
              this.snackbartest = {
                color: "green",
                icon: "mdi-check-all",
                mode: "multi-line",
                position: "top",
                timeout: 2500,
                title: "Information",
                text: this.textmessage,
                visible: true,
              };
            }
          } else {
            this.textmessage = up.message;
            this.snackbartest = {
              color: "red",
              icon: "mdi-check-all",
              mode: "multi-line",
              position: "top",
              timeout: 2500,
              title: "Erreur",
              text: this.textmessage,
              visible: true,
            };
          }

        } else {
          //console.log("update event without image");
            const res = await api.createUpdatedata(
              "events/update/" + this.id,
              fd
            );
            if (res.status == 200) {
              this.textmessage = res.message;
              this.snackbartest = {
                color: "green",
                icon: "mdi-check-all",
                mode: "multi-line",
                position: "top",
                timeout: 2500,
                title: "Information",
                text: this.textmessage,
                visible: true,
              };
              this.dialog = false;
            } else {
              this.textmessage = res.message;
              this.snackbartest = {
                color: "green",
                icon: "mdi-check-all",
                mode: "multi-line",
                position: "top",
                timeout: 2500,
                title: "Information",
                text: this.textmessage,
                visible: true,
              };
            }
        }



      } else { // add new event
        let fp = new FormData();
        fp.append("id", this.id);
        fp.append("name", this.lien_image.name);
        fp.append("image", this.lien_image);
        const up = await api.upload_Only("events/upload", fp);
        //console.log(up.message);
        if (up.status == 200) { // upload image success
          const res = await api.createUpdatedata("events/add", fd);
          if (res.status == 200) {
            this.textmessage = res.message;
            this.snackbartest = {
              color: "green",
              icon: "mdi-check-all",
              mode: "multi-line",
              position: "top",
              timeout: 2500,
              title: "Information",
              text: this.textmessage,
              visible: true,
            };
          } else {
            this.textmessage = res.message;
            this.snackbartest = {
              color: "green",
              icon: "mdi-check-all",
              mode: "multi-line",
              position: "top",
              timeout: 2500,
              title: "Information",
              text: this.textmessage,
              visible: true,
            };
          }
        } else {
          this.textmessage = up.message;
          this.snackbartest = {
            color: "red",
            icon: "mdi-check-all",
            mode: "multi-line",
            position: "top",
            timeout: 2500,
            title: "Erreur",
            text: this.textmessage,
            visible: true,
          };
        }
      }

      this.clear();
      this.readAll();
    },

    delete: async function () {
      let fd = new FormData();
      fd.append("id", this.id);
      const res = await api.createUpdatedata("events/delete/" + this.id, fd);
      this.readAll();
      this.dialogDelete = false;
    },

    supdata() {
      this.delete();
    },

    save() {
      //console.log(this.lien_image);
      if (!this.textetat) { // add new event
      //console.log("save event "+this.lien_image);
        if (this.titre == "" || this.description == "" || this.details == "" || this.event_periode == "" ||
          this.pays == "" || this.lien_image == "" || this.lien_reunion == "" || this.date_evenement == ""
        ) {
          this.textmessage = "Veuillez remplir les champs obligatoires";
          this.snackbartest = {
            color: "red",
            icon: "mdi-alert",
            mode: "multi-line",
            position: "top",
            timeout: 2500,
            title: "Erreur",
            text: this.textmessage,
            visible: true,
          };
          return;
        }
      } else { // update event
      //console.log("update event "+this.lien_image);
        if (this.titre == "" || this.description == "" || this.details == "" || this.event_periode == "" ||
          this.pays == "" || this.lien_reunion == "" || this.date_evenement == ""
        ) {
          this.textmessage = "Veuillez remplir les champs obligatoires update";
          this.snackbartest = {
            color: "red",
            icon: "mdi-alert",
            mode: "multi-line",
            position: "top",
            timeout: 2500,
            title: "Erreur",
            text: this.textmessage,
            visible: true,
          };
          return;
        }
      }
      this.createUpdatedata();
    },

    nouveau() {
      this.textetat = false;
      this.clear();
      this.dialog = true;
    },

    editItem(item) {
      this.id = item.id;
      this.titre = item.titre;
      this.description = item.description;
      this.details = item.details;
      this.pays = item.pays;
      this.lien_image = "";
      this.lien_reunion = item.lien_reunion;
      this.event_periode = item.event_periode;
      this.date_evenement = item.date_evenement;
      this.textetat = true;
      this.dialog = true;
    },

    deleteItem(item) {
      this.id = item.id;
      this.dialogDelete = true;
    },

    clear() {
      this.id = "0";
      this.titre = "";
      this.description = "";
      this.details = "";
      this.pays = "";
      this.lien_image = "";
      this.lien_reunion = "";
      this.event_periode = "";
      this.date_evenement = "";
      this.textetat = false;
    },
  },

  mounted() {
    this.readAll();
    this.readAllpays();
    this.readCategorie();
  },
};
</script>

<style lang="scss" scoped>
.app-content-wrapper {
  position: relative;
  display: flex;
  overflow: hidden;
  height: calc(100vh - 200px);

  .app-overlay {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    display: none;
    z-index: 2;
    transition: all 0.3s ease-in;

    @media only screen and (max-width: 959px) {
      &.open {
        display: block;
        transition: all 0.3s ease-in;
      }
    }
  }

  .app-sidebar {
    width: 280px;
    position: relative;
    transition: all 0.3s ease-in;

    @media only screen and (max-width: 959px) {
      position: absolute;
      left: -340px;
      z-index: 5;
      height: calc(100vh - 120px);
      transition: all 0.3s ease-in;

      &.open {
        left: 0;
      }
    }

    .app-sidebar-header {
      padding: 0.75rem 1.25rem;
      margin-bottom: 0;
      // border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }

    .app-sidebar-body {
      // overflow-y: scroll;
      // height: 100%;
      padding-top: 3.375rem;
      width: 280px;
    }
  }

  .app-content {
    width: calc(100% - 280px);

    @media only screen and (max-width: 959px) {
      width: 100%;
    }

    // margin-left: 30px;
    .app-header {
      padding: 0.75rem 1.25rem;
      margin-bottom: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }

    .app-body {
      flex: 1 1 auto;
      padding-top: 3.375rem;
      // overflow-y: scroll;
      height: calc(100% - 130px);
    }

    .app-footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 130px;
    }
  }
}

.app-icon {
  display: none;

  @media only screen and (max-width: 959px) {
    display: block;
  }
}

.eg-filemanager {
  background-color: #fff;
}

.filemanager-content {
  grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
}

.apps-wrapper {
  &.--filemanager {
    .nested-sidebar-toggle {
      @media (min-width: 959px) {
        display: none;
      }
    }
  }
}
</style>